import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import TableContainer from "../../../Components/Common/TableContainer";

import DeleteModal from "../../../Components/Common/DeleteModal";
import ModalAddTicket from "./ModalAddTicket";

import { toast } from "react-toastify";
import Loader from "../../../Components/Common/Loader";
import axios from "axios";

const TicketsData = () => {
  const [ticketsList, setTicketList] = useState([]);
  const [loading, setLoading] = useState(false);

  // Delete Tickets
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modal]);

  const getTicketsList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tickets`
      );

      if (response.sucesso && response.sucesso === 1) {
        setTicketList(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao acessar a API.");
      console.log(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getTicketsList();
  }, [getTicketsList]);

  const deleteTicket = useCallback(
    async (ticket) => {
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      setLoading(true);

      try {
        const response = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API_URL}/tickets/${ticket.id}/status`,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          data: {
            status: ticket.status === "fechado" ? "em_progresso" : "fechado",
          },
        });

        if (response.sucesso && response.sucesso === 1) {
          toast.success(response.mensagem);
          getTicketsList();
        } else {
          toast.error(response.mensagem);
          console.log(response);
        }
      } catch (error) {
        toast.error("Erro na API");
        console.log(error);
      }
      setLoading(false);
    },
    [getTicketsList]
  );

  const handleDeleteTicket = useCallback(
    (arg) => {
      const ticket = arg;
      deleteTicket(ticket);
      setDeleteModal(false);
    },
    [deleteTicket]
  );

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".ticketCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      deleteTicket(element.value);
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".ticketCheckBox:checked");
    ele && ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="ticketCheckBox form-check-input"
              value={cellProps.row.original.id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Título",
        accessor: "titulo",
        filterable: true,
        Cell: (cell) => {
          return <b style={{ color: "darkblue" }}>{cell.value}</b>;
        },
      },
      {
        Header: "Usuário",
        accessor: "requester.name",
        filterable: true,
        Cell: (cell) => {
          return <b style={{ color: "darkred" }}>{cell.value}</b>;
        },
      },
      {
        Header: "Responsável",
        accessor: "assignee.name",
        filterable: true,
        Cell: (cell) => {
          return (
            <>
              {cell?.value ? (
                <b style={{ color: "darkgreen" }}>{cell.value}</b>
              ) : (
                <b style={{ color: "darkgray" }}>Aguardando...</b>
              )}
            </>
          );
        },
      },
      {
        Header: "Data Início",
        accessor: "created_at",
        filterable: true,
        Cell: (cell) => {
          return dateFormat(cell.value);
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cell) => {
          return (
            <>
              {cell.value === "novo" ? (
                <Badge color="info">Nova</Badge>
              ) : cell.value === "em_progresso" ? (
                <Badge color="warning">Em Progresso</Badge>
              ) : (
                <Badge color="danger">Fechada</Badge>
              )}
            </>
          );
        },
      },
      {
        Header: "Prioridade",
        accessor: "prioridade",
        filterable: true,
        Cell: (cell) => {
          return (
            <>
              {cell.value === "baixa" ? (
                <Badge color="primary">Baixa</Badge>
              ) : cell.value === "media" ? (
                <Badge color="warning">Média</Badge>
              ) : (
                <Badge color="danger">Alta</Badge>
              )}
            </>
          );
        },
      },
      {
        Header: "Ações",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to={`/tickets-details?ticket_id=${cellProps.row.original.id}`}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              {cellProps.row.original.status !== "fechado" ? (
                <li className="list-inline-item" title="Encerrar">
                  <Link
                    to="#"
                    className="text-danger d-inline-block remove-item-btn"
                    onClick={() => {
                      const ticketData = cellProps.row.original;
                      handleDeleteTicket(ticketData);
                    }}
                  >
                    <i className="ri-delete-bin-5-fill fs-16"></i>
                  </Link>
                </li>
              ) : (
                <li className="list-inline-item" title="Reativar">
                  <Link
                    to="#"
                    className="text-success d-inline-block remove-item-btn"
                    onClick={() => {
                      const ticketData = cellProps.row.original;
                      handleDeleteTicket(ticketData);
                    }}
                  >
                    <i className="ri-thumb-up-fill fs-16"></i>
                  </Link>
                </li>
              )}
            </ul>
          );
        },
      },
    ],
    [handleDeleteTicket, checkedAll]
  );

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteTicket}
          onCloseClick={() => setDeleteModal(false)}
        />
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />
        <Col lg={12}>
          <Card>
            <CardHeader className="border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">Tickets</h5>
                <div className="flex-shrink-0">
                  <div className="d-flex flex-wrap gap-2">
                    <button
                      className="btn btn-success add-btn"
                      onClick={() => {
                        toggle();
                      }}
                    >
                      <i className="ri-add-line align-bottom"></i> Novo Ticket
                    </button>{" "}
                    {isMultiDeleteButton && (
                      <button
                        className="btn btn-success"
                        onClick={() => setDeleteModalMulti(true)}
                      >
                        <i className="ri-delete-bin-2-line"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </CardHeader>

            <CardBody className="pt-0">
              {!loading ? (
                <TableContainer
                  columns={columns}
                  data={ticketsList || []}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  customPageSize={8}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass=""
                  thClass=""
                  SearchPlaceholder="Busque pelos detalhes do Ticket"
                />
              ) : (
                <Loader />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ModalAddTicket
        modal={modal}
        toggle={toggle}
        getTicketsList={getTicketsList}
      />
    </React.Fragment>
  );
};

export default TicketsData;
