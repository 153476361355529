import React, { useState, useEffect, useCallback } from "react";
import Section from "./Section";
import { Container, Row } from "reactstrap";
import TicketDescription from "./TicketDescription";
import TicketDetails from "./TicketDetails";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { use } from "echarts";

function TicketsDetails() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ticketIdValue = queryParams.get("ticket_id");
  const [ticketData, setTicketData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ticketList, setTicketList] = useState([]);

  const getTicketData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tickets/${ticketIdValue}`
      );

      if (response && response.sucesso === 1) {
        setTicketData(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao acessar a API.");
      console.log(error);
    }
    setLoading(false);
  }, [ticketIdValue]);

  const getChildren = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tickets/${ticketData?.id}/get-children`
      );

      if (response.sucesso && response.sucesso === 1) {
        setTicketList(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao acessar a API.");
      console.log(error);
    }
    setLoading(false);
  }, [setLoading, ticketData, setTicketList]);

  useEffect(() => {
    if (!ticketData) {
      getTicketData();
    }
  }, [ticketData, getTicketData]);

  useEffect(() => {
    if (ticketData) {
      getChildren();
    }
  }, [ticketData, getChildren]);

  const dateFormat = (mysqlDate) => {
    let d = new Date(mysqlDate),
      months = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
    let minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear() +
      " " +
      d.getHours() +
      ":" +
      minutes
    ).toString();
  };

  document.title = "Detalhes do Ticket | Painel Líder Fibra";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Section ticket={ticketData} dateFormat={dateFormat} />
          </Row>
          <Row>
            <TicketDescription
              ticket={ticketData}
              loading={loading}
              setLoading={setLoading}
              dateFormat={dateFormat}
              ticketList={ticketList}
              getChildren={getChildren}
              setTicketData={setTicketData}
            />
            <TicketDetails
              ticket={ticketData}
              loading={loading}
              dateFormat={dateFormat}
              ticketList={ticketList}
              getChildren={getChildren}
              setTicketData={setTicketData}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default TicketsDetails;
