import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import img12 from "../../../assets/images/small/img-12.jpg";
import img2 from "../../../assets/images/small/img-2.jpg";

// Import Images
import offlineImg from "../../../assets/images/logo-light.png";
import { toast } from "react-toastify";

const Atendimento = () => {
  function handleCopy(text) {
    navigator.clipboard
      .writeText(text)
      .then(function () {
        toast.success("Copiado com sucesso!");
      })
      .catch(function (err) {
        toast.error("Não foi possível copiar o código.");
      });
  }
  document.title = "Atendimento | Líder Fibra";
  return (
    <React.Fragment>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-1">
          <Container>
            <Row className="justify-content-center">
              <Col xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="p-3">
                    <div className="text-center">
                      <img
                        src={offlineImg}
                        alt=""
                        style={{ width: "50%" }}
                        className="pb-1 pt-1"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xl={5}>
                <Card>
                  <Row className="g-0">
                    <Col md={4}>
                      <img
                        className="rounded-start img-fluid h-100 object-cover"
                        src={img12}
                        alt="Card"
                      />
                    </Col>
                    <Col md={8}>
                      <CardHeader>
                        <h5 className="card-title mb-0">
                          Atendimento no WhatsApp
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <p className="card-text mb-2">
                          Precisa de suporte rápido, informações sobre nossos
                          planos de fibra óptica ou tem alguma dúvida? Agora
                          você pode entrar em contato com a equipe de
                          atendimento da Líder Fibra diretamente pelo WhatsApp!
                          É fácil, rápido e você pode fazer isso de qualquer
                          lugar.
                        </p>
                        <p className="card-text">
                          <Button
                            color="success"
                            onClick={() => handleCopy("+551142155440")}
                          >
                            <FeatherIcon icon="copy" /> Copie Aqui o Número
                          </Button>
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xl={5}>
                <Card>
                  <Row className="g-0">
                    <Col md={8}>
                      <CardBody>
                        <h5 className="card-title mb-2">Como funciona?</h5>
                        <ul>
                          <li className="card-text mb-0">
                            Adicione nosso número à sua lista de contatos:{" "}
                            <b>(11) 4215-5440</b>
                          </li>
                          <li className="card-text text-muted mb-0">
                            Abra o WhatsApp e procure pelo contato da Líder
                            Fibra.
                          </li>
                          <li className="card-text mb-0">
                            Envie-nos uma mensagem com sua dúvida, pedido ou
                            preocupação.
                          </li>
                          <li className="card-text text-muted mb-0">
                            Receba uma resposta rápida de nossa equipe de
                            atendentes.
                          </li>
                        </ul>
                      </CardBody>
                      <div className="card-footer">
                        <p className="card-text">
                          <b>
                            <small>
                              Estamos disponíveis para atendê-lo de segunda a
                              sexta-feira, <b className="text-success">das 8h às 20h</b>, e aos sábados,{" "}
                              <b className="text-success">das 8h às 18h</b>.
                            </small>
                          </b>
                        </p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <img
                        className="rounded-end img-fluid h-100 object-cover"
                        src={img2}
                        alt="Card"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Atendimento;
