import React from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import img from "../../../assets/images/companies/img-4.png";

const Section = ({ ticket, dateFormat }) => {
  
  return (
    <React.Fragment>
      <Col lg={12}>
        <Card className="mt-n4 mx-n4 mb-n5">
          <div className="bg-soft-warning">
            <CardBody className="pb-4 mb-5">
              <Row>
                <div className="col-md">
                  <Row className="align-items-center">
                    <div className="col-md-auto">
                      <div className="avatar-md mb-md-0 mb-4">
                        <div className="avatar-title bg-white rounded-circle">
                          <img src={img} alt="" className="avatar-sm" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md">
                      <h4 className="fw-semibold" id="ticket-title">
                        #{ticket?.id} - {ticket?.titulo}
                      </h4>
                      <div className="hstack gap-3 flex-wrap">
                        <div className="text-muted">
                          <i className="ri-building-line align-bottom me-1"></i>{" "}
                          <span id="ticket-client">
                            {ticket?.requester.name}
                          </span>
                        </div>
                        <div className="vr"></div>
                        <div className="text-muted">
                          Início em :{" "}
                          <span className="fw-medium" id="create-date">
                            {dateFormat(ticket?.created_at)}
                          </span>
                        </div>
                        <div className="vr"></div>
                        {ticket?.status === "novo" ? (
                          <div
                            className="badge rounded-pill bg-info fs-12"
                            id="ticket-status"
                          >
                            Novo
                          </div>
                        ) : ticket?.status === "em_progresso" ? (
                          <div
                            className="badge rounded-pill bg-warning fs-12"
                            id="ticket-status"
                          >
                            Em progresso
                          </div>
                        ) : (
                          <div
                            className="badge rounded-pill bg-danger fs-12"
                            id="ticket-status"
                          >
                            Fechado
                          </div>
                        )}

                        {ticket?.prioridade === "baixa" ? (
                          <div
                            className="badge rounded-pill bg-info fs-12"
                            id="ticket-status"
                          >
                            Baixa
                          </div>
                        ) : ticket?.prioridade === "media" ? (
                          <div
                            className="badge rounded-pill bg-warning fs-12"
                            id="ticket-status"
                          >
                            Média
                          </div>
                        ) : (
                          <div
                            className="badge rounded-pill bg-danger fs-12"
                            id="ticket-status"
                          >
                            Alta
                          </div>
                        )}
                      </div>
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Section;
