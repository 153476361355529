import React, { useCallback, useEffect, useState } from "react";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";
import axios from "axios";
import {toast} from "react-toastify";

function App({ user_email, app_id }) {
  
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
