import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";

//import COmponents
import UpgradeAccountNotice from "./UpgradeAccountNotice";
// import UsersByDevice from "./UsersByDevice";
// import Widget from "./Widget";
import BreadCrumb from "../../Components/Common/BreadCrumb";
// import AudiencesMetrics from "./AudiencesMetrics";
// import AudiencesSessions from "./AudiencesSessions";
// import LiveUsers from "./LiveUsers";
// import TopReferrals from "./TopReferrals";
// import TopPages from "./TopPages";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../Components/Common/Loader";
import { Link } from "react-router-dom";
import avatar7 from "../../assets/images/icon-contrato.png";

const DashboardAnalytics = () => {
  const [loading, setLoading] = useState(false);
  const [contratos, setContratos] = useState([]);
  const [user_email, setUserEmail] = useState(null);
  const [app_id, setAppId] = useState(null);
  const [user, setUser] = useState({});

  const mobileLogin = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/mobile/contratos`,
        {
          email: user_email,
          app_token: app_id,
        }
      );

      if (response.data) {
        const fullName = response.data.cliente.razao.split(" ");
        const firstName = fullName[0];
        const lastName = fullName[fullName.length - 1];

        setUser({
          id: response.data.cliente.id,
          first_name: firstName,
          last_name: lastName,
          full_name: response.data.cliente.razao,
          email: response.data.cliente.email,
          role: "cliente",
        });
        setContratos(response.data.contratos);
      } else {
        toast.error(
          response.message ? response.message : "Ocorreu um erro desconhecido."
        );
        console.log(response);
      }
    } catch (error) {
      toast.error("Ocorreu um erro desconhecido.");
      console.log(error);
    }
    setLoading(false);
  }, [user_email, app_id]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setUserEmail(urlParams.get("email"));
    setAppId(urlParams.get("app_id"));

    if (app_id) {
      mobileLogin();
    }
  }, [mobileLogin, app_id]);

  document.title = "Contratos | Líder Fibra";
  return (
    <React.Fragment>
      <div className="page-content pt-4">
        <Container fluid>
          {/* <BreadCrumb title="Contratos" pageTitle={user_email} /> */}
          {loading ? (
            <Loader />
          ) : contratos.length === 0 ? (
            <>
              <Row>
                <Col xxl={5}>
                  <Card className="card-dark">
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img
                            src={avatar7}
                            alt=""
                            className="avatar-sm rounded-circle"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="card-text">
                            <span className="fw-medium">
                              Você não possui contratos ativos. Entre em contato com nosso time de vendas para contratar um plano.
                            </span>
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col xxl={5}>
                  {contratos.map((contrato) => (
                    <UpgradeAccountNotice
                      key={contrato.id}
                      contrato={contrato}
                    />
                  ))}
                  {/* <Widget /> */}
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardAnalytics;
