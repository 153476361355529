import React from "react";
import { Navigate } from "react-router-dom";

//dashboard e página de clientes
import DashboardCrm from "../pages/DashboardCrm";
import DashboardAnalytics from "../pages/DashboardAnalytics";

import ListView from "../pages/SupportTickets/ListView";
import TicketsDetails from "../pages/SupportTickets/TicketsDetails";
import InvoiceList from "../pages/Invoices/InvoiceList";
import InvoiceDetails from "../pages/Invoices/InvoiceDetails";

import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";
import Atendimento from "../pages/AuthenticationInner/Errors/Atendimento";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

const user = JSON.parse(sessionStorage.getItem("authUser"));

const authProtectedRoutes = [
  //sistema
  { path: "/dashboard", component: <DashboardCrm /> },

  { path: "/tickets-list", component: <ListView /> },
  { path: "/tickets-details", component: <TicketsDetails /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/auth-offline" />,
  },
  { path: "*", component: <Navigate to="/auth-offline" /> },
];

const publicRoutes = [
  // Faturas
  { path: "/faturas", component: <InvoiceList /> },
  { path: "/fatura", component: <InvoiceDetails /> },

  // Solicitações
  { path: "/contratos", component: <DashboardAnalytics /> },
  { path: "/speedtest", component: <Offlinepage />},

  // Offline
  { path: "/auth-offline", component: <Offlinepage /> },

  // Atendimento
  { path: "/atendimento", component: <Atendimento /> },
];

export { authProtectedRoutes, publicRoutes };
