import React from 'react';
import moment from 'moment';

const FormatDate = ({ date }) => {
  const formattedDate = moment(date, "YYYY-MM-DD");

  if (!formattedDate.isValid()) {
    return <span>Data inválida</span>;
  }

  const month = formattedDate.format("MMM");
  const day = formattedDate.format("DD");
  const year = formattedDate.format("YYYY");

  return (
    <>
      <b>{month}</b>, {day} <small>{year}</small>
    </>
  );
};

export default FormatDate;
