import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import bell from "../../assets/images/svg/bell.svg";

//SimpleBar
import SimpleBar from "simplebar-react";

const NotificationDropdown = () => {
  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };
  const [loading, setLoading] = useState(false);
  const [notificacoes, setNotificacoes] = useState([]);

  //Tab
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];

    // Obtém a hora e o minuto
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");

    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear() +
      " " +
      hours +
      ":" +
      minutes
    ).toString();
  };

  const setLida = async (id) => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/notificacoes/ler/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        fetchNotificoes();
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API.");
      console.log(error);
    }
  };

  const fetchNotificoes = useCallback(async () => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/notificacoes`,
        {
          params: {
            status: 0,
          },
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.sucesso && response.sucesso === 1) {
        setNotificacoes(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API.");
      console.log(error);
    }
    setLoading(false);
  }, [setLoading, setNotificacoes]);

  useEffect(() => {
    fetchNotificoes();
  }, [fetchNotificoes]);
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
        >
          <i className="bx bx-bell fs-22"></i>
          <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
            {notificacoes.length}
            <span className="visually-hidden">não lidas</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white">
                    {" "}
                    Notificações{" "}
                  </h6>
                </Col>
                <div className="col-auto dropdown-tabs">
                  <span className="badge badge-soft-light fs-13">
                    {" "}
                    {notificacoes.length} Novas
                  </span>
                </div>
              </Row>
            </div>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="py-2 ps-2">
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                {loading ? (
                  <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    padding: "15px",
                  }}
                >
                  <Spinner height={50} width={50} />
                </div>
                ) : notificacoes.length > 0 ? (
                  notificacoes.map((notificacao) => (
                    <>
                      <div className="text-reset notification-item d-block dropdown-item position-relative">
                        <div className="d-flex">
                          <div className="avatar-xs me-3">
                            <span
                              className={`avatar-title bg-soft-${notificacao.badge} text-${notificacao.badge} rounded-circle fs-16`}
                            >
                              <i className="bx bx-message-square-dots"></i>
                            </span>
                          </div>
                          <div className="flex-1">
                            <Link
                              to={notificacao.link}
                              className="stretched-link"
                            >
                              <h6 className="mt-0 mb-2 fs-13 lh-base">
                                {notificacao.texto}
                              </h6>
                            </Link>
                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span>
                                <i className="mdi mdi-clock-outline"></i>{" "}
                                {dateFormat(notificacao.created_at)}
                              </span>
                            </p>
                          </div>
                          <div className="px-2 fs-15">
                            <div className="form-check notification-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                onChange={() => setLida(notificacao.id)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="all-notification-check03"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <>
                    <div className="w-25 w-sm-50 pt-3 mx-auto">
                      <img src={bell} className="img-fluid" alt="user-pic" />
                    </div>
                    <div className="text-center pb-5 mt-2">
                      <h6 className="fs-18 fw-semibold lh-base">
                        Não existem novas notificações
                      </h6>
                    </div>
                  </>
                )}

                {/* <div className="my-3 text-center">
                  <button
                    type="button"
                    className="btn btn-soft-success waves-effect waves-light"
                  >
                    Ver todas{" "}
                    <i className="ri-arrow-right-line align-middle"></i>
                  </button>
                </div> */}
              </SimpleBar>
            </TabPane>
          </TabContent>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
