import React, { useState, useCallback, useEffect } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Components/Common/Loader";

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import { Link } from "react-router-dom";

const TicketDescription = ({
  ticket,
  loading,
  dateFormat,
  ticketList,
  getChildren,
  setTicketData
}) => {
  const [reply, setReply] = useState("");
  const [loadingReply, setLoadingReply] = useState(false);

  const handleReply = async (e) => {
    e.preventDefault();
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoadingReply(true);

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/tickets/${ticket?.id}/reply`,
        data: {
          texto: reply,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        getChildren(ticket?.id);
        setTicketData(response.data_pai);
        setReply("");
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoadingReply(false);
  };

  return (
    <React.Fragment>
      <Col xxl={9}>
        <Card>
          <CardBody className="p-4">
            <div className="mt-4">{ticket?.texto}</div>
          </CardBody>
          <CardBody className="p-4">
            <h5 className="card-title mb-4">Atualizações</h5>
            {loading ? (
              <Loader />
            ) : (
              <SimpleBar className="px-3 mx-n3">
                {ticketList?.map((ticketChild) => (
                  <div className="d-flex mb-4" key={ticketChild.id}>
                    <div
                      className={`flex-shrink-0 avatar-xs rounded-circle bg-${
                        ticketChild.requester.id === ticket.requester.id
                          ? "info"
                          : "success"
                      } d-flex justify-content-center align-items-center`}
                      style={{
                        color: "lightyellow",
                        fontWeight: "bold",
                        fontSize: "larger",
                      }}
                    >
                      {ticketChild.requester.name.charAt(0)}
                    </div>

                    <div className="flex-grow-1 ms-3">
                      <h5 className="fs-13">
                        {ticketChild.requester.name}{" "}
                        <small className="text-muted">
                          {dateFormat(ticketChild.created_at)}
                        </small>{" "}
                        {/* Note: Adjust this date if needed */}
                      </h5>
                      <p className="text-muted">
                        {ticketChild.texto.includes("ENCERRADO")
                          ? ticketChild.texto
                              .split("ENCERRADO")
                              .map((part, index, array) => (
                                <React.Fragment key={index}>
                                  {part}
                                  {index !== array.length - 1 && (
                                    <div
                                      className="badge rounded-pill bg-danger fs-12"
                                      id="ticket-status"
                                    >
                                      Fechado
                                    </div>
                                  )}
                                </React.Fragment>
                              ))
                          : ticketChild.texto.includes("REABERTO")
                          ? ticketChild.texto
                              .split("REABERTO")
                              .map((part, index, array) => (
                                <React.Fragment key={index}>
                                  {part}
                                  {index !== array.length - 1 && (
                                    <div
                                      className="badge rounded-pill bg-success fs-12"
                                      id="ticket-status"
                                    >
                                      Reaberto
                                    </div>
                                  )}
                                </React.Fragment>
                              ))
                          : ticketChild.texto}
                      </p>
                    </div>
                  </div>
                ))}
              </SimpleBar>
            )}
            {ticket?.status !== "fechado" && (
              <form action="#" className="mt-3">
                <Row className="g-3">
                  <Col lg={12}>
                    <Label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Resposta
                    </Label>
                    <textarea
                      className="form-control bg-light border-light"
                      rows="3"
                      placeholder="Responda aqui ao ticket..."
                      value={reply}
                      onChange={(e) => setReply(e.target.value)}
                    ></textarea>
                  </Col>
                  <Col lg={12} className="text-end">
                    <Link
                      to="#"
                      onClick={handleReply}
                      className="btn btn-success"
                      disabled={loadingReply}
                    >
                      Postar Resposta
                    </Link>
                  </Col>
                </Row>
              </form>
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default TicketDescription;
