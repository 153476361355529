import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";

const ModalAddTicket = ({ modal, toggle, getTicketsList }) => {
  const userLogged = JSON.parse(sessionStorage.getItem("authUser"));

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userAssigned, setUserAssigned] = useState(0);
  const [prioridade, setPrioridade] = useState("baixa");
  const [titulo, setTitulo] = useState("");
  const [texto, setTexto] = useState("");

  const handleAddTicket = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/tickets`,
        data: {
          titulo: titulo,
          texto: texto,
          prioridade: prioridade,
          user_assigned: userAssigned,
        },
        headers: {
          Authorization: `Bearer ${userLogged.token}`,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        getTicketsList();
        setTitulo("");
        setTexto("");
        setPrioridade("baixa");
        setUserAssigned(0);
        toggle();
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  };

  const fetchUsers = useCallback(async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/users`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        params: {
          status: "ativo",
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        setUsers(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (modal) fetchUsers();
  }, [fetchUsers, modal]);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      centered
      size="lg"
      className="border-0"
      modalClassName="zoomIn"
    >
      <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
        Novo Ticket
      </ModalHeader>
      <Form
        className="tablelist-form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ModalBody>
          <Row className="g-3">
            <Col lg={12}>
              <div>
                <Label htmlFor="tasksTitle-field" className="form-label">
                  Título
                </Label>
                <Input
                  className="form-control"
                  placeholder="Digite o Título do Ticket"
                  type="text"
                  maxLength={100}
                  value={titulo}
                  onChange={(e) => setTitulo(e.target.value)}
                />
              </div>
            </Col>
            <Col lg={6}>
              <Label className="form-label">Responsável</Label>
              <Input
                className="form-control"
                type="select"
                value={userAssigned}
                onChange={(e) => setUserAssigned(parseInt(e.target.value))}
              >
                <option value="0">Suporte Geral</option>
                {users
                  .filter((user) => user.id !== userLogged.data._id)
                  .map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                      {user.setores.length > 0
                        ? ` (${user.setores
                            .map((setor) => setor.setor)
                            .join(", ")})`
                        : ""}
                    </option>
                  ))}
              </Input>
            </Col>

            <Col lg={6}>
              <Label className="form-label">Prioridade</Label>
              <Input
                type="select"
                className="form-select"
                value={prioridade}
                onChange={(e) => setPrioridade(e.target.value)}
              >
                <option value="baixa">Baixa</option>
                <option value="media">Média</option>
                <option value="alta">Alta</option>
              </Input>
            </Col>
            <Col lg={12}>
              <Label className="form-label">Texto</Label>
              <Input
                type="textarea"
                className="form-control"
                placeholder="Digite o texto do Ticket"
                rows="5"
                maxLength={1000}
                value={texto}
                onChange={(e) => setTexto(e.target.value)}
              />
            </Col>
          </Row>
        </ModalBody>
        <div className="modal-footer">
          <div className="hstack gap-2 justify-content-end">
            <button
              onClick={() => {
                toggle();
              }}
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleAddTicket}
              disabled={loading}
            >
              + Ticket
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddTicket;
