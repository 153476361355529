import React, { useState, useEffect, useMemo, useCallback } from "react";
import { CardBody, Row, Col, Card, Container, Button } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import CountUp from "react-countup";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import FormatDate from "../../Components/Common/FormatDate";
import avatar7 from "../../assets/images/icon-fatura.png";

//Import Icons
import FeatherIcon from "feather-icons-react";

import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";

const InvoiceList = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [user, setUser] = useState({});

  const [user_email, setUserEmail] = useState(null);
  const [app_id, setAppId] = useState(null);

  function handleCopy(text) {
    navigator.clipboard
      .writeText(text)
      .then(function () {
        toast.success("Copiado com sucesso!");
      })
      .catch(function (err) {
        toast.error("Não foi possível copiar o código.");
      });
  }

  const mobileLogin = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/mobile/faturas-abertas`,
        {
          email: user_email,
          app_token: app_id,
        }
      );

      if (response.data) {
        const fullName = response.data.cliente.razao.split(" ");
        const firstName = fullName[0];
        const lastName = fullName[fullName.length - 1];

        setUser({
          id: response.data.cliente.id,
          first_name: firstName,
          last_name: lastName,
          full_name: response.data.cliente.razao,
          email: response.data.cliente.email,
          role: "cliente",
        });
        setInvoices(response.data.faturas);
        console.log("Sucesso", response.data);
      } else {
        toast.error(
          response.message ? response.message : "Ocorreu um erro desconhecido."
        );
        console.log(response);
      }
    } catch (error) {
      toast.error("Ocorreu um erro desconhecido.");
      console.log(error);
    }
    setLoading(false);
  }, [user_email, app_id]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setUserEmail(urlParams.get("email"));
    setAppId(urlParams.get("app_id"));

    if (app_id) {
      mobileLogin();
    }
  }, [mobileLogin, app_id]);

  const isDatePast = (date) => {
    const today = moment();
    const dueDate = moment(date, "YYYY-MM-DD");
    return dueDate.isBefore(today);
  };

  document.title = "Faturas Em Aberto | Líder Fibra";

  return (
    <React.Fragment>
      <div className="page-content pt-4">
        <Container fluid>
          {/* <BreadCrumb title="Faturas Em Aberto" pageTitle={user_email} /> */}
          <Row>
            {invoices.length === 0 ? (<Card className="card-secondary">
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img
                            src={avatar7}
                            alt=""
                            className="avatar-sm rounded-circle"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="card-text">
                            <span className="fw-medium">
                              Oba! Não há faturas abertas a serem pagas!
                            </span>
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>) :  invoices.map((invoice, key) => (
              <React.Fragment key={key}>
                <Col xl={3} md={6}>
                  <Card className="card-animate">
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <p className="text-uppercase fw-medium text-muted mb-0">
                            Fatura Mensal
                          </p>
                        </div>
                        <div className="flex-shrink-0">
                          <h5 className={`fs-14 mb-0 text-info`}>
                            <FormatDate date={invoice.data_vencimento} />
                          </h5>
                        </div>
                      </div>
                      <div className="d-flex align-items-end justify-content-between mt-4">
                        <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                            <CountUp
                              start={0}
                              prefix="R$ "
                              decimals="2"
                              decimal=","
                              end={invoice.valor}
                              duration={2}
                              className="counter-value"
                            />
                          </h4>
                          {invoice.linha_digitavel === "" ? (
                            <Button color="dark">Sem Código de Barras</Button>
                          ) : (
                            <Button
                              color="info"
                              onClick={() => handleCopy(invoice.linha_digitavel)}
                            >
                              <FeatherIcon icon="copy" /> Copiar Código de
                              Barras
                            </Button>
                          )}
                        </div>
                        <Button
                          color={
                            isDatePast(invoice.data_vencimento)
                              ? "danger"
                              : "success"
                          }
                          className="btn-sm"
                        >
                          <FeatherIcon
                            icon={
                              isDatePast(invoice.data_vencimento)
                                ? "x-octagon"
                                : "clock"
                            }
                          />{" "}
                          <b>
                            {isDatePast(invoice.data_vencimento)
                              ? "Em Atraso"
                              : "Em Dia"}
                          </b>
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </React.Fragment>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoiceList;
