import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isTickets, setIsTickets] = useState(false);
  const [isFinanceiro, setIsFinanceiro] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);
  const [isCrypto, setIsCrypto] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "Tickets") {
      setIsTickets(false);
    }
    if (iscurrentState !== "Financeiro") {
      setIsFinanceiro(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "BaseUi") {
      setIsBaseUi(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }
    if (iscurrentState !== "MuliLevel") {
      setIsMultiLevel(false);
    }
    if (iscurrentState === "Widgets") {
      history("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isTickets,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
  ]);

  const user = JSON.parse(sessionStorage.getItem("authUser"));

  let menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
  ];
  const menuAdmin = [
    {
      id: "sistema",
      label: "Sistema",
      icon: "bx bxs-dashboard",
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
      subItems: [
        // {
        //   id: "dashboard",
        //   label: "Dashboard",
        //   link: "/dashboard",
        //   parentId: "sistema",
        // },
        {
          id: "produtos",
          label: "Produtos",
          link: "/produtos",
          parentId: "sistema",
        },
        {
          id: "usuarios",
          label: "Usuarios",
          link: "/usuarios",
          parentId: "sistema",
        },
      ],
    },
    {
      id: "financeiro",
      label: "Financeiro",
      icon: "bx bx-money",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsFinanceiro(!isFinanceiro);
        setIscurrentState("Financeiro");
        updateIconSidebar(e);
      },
      stateVariables: isFinanceiro,
      subItems: [
        {
          id: "faturas-aberto",
          label: "Faturas em Aberto",
          link: "/faturas-aberto",
          parentId: "financeiro",
        },
        {
          id: "faturas-revendedores",
          label: "Faturas Revendedores",
          link: "/faturas-revendedores",
          parentId: "financeiro",
        },
      ],
    },
  ];
  const menuRevendedor = [
    {
      id: "apps",
      label: "Clientes",
      icon: "bx bx-user",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsApps(!isApps);
        setIscurrentState("Apps");
        updateIconSidebar(e);
      },
      stateVariables: isApps,
      subItems: [
        {
          id: "clientes-profile",
          label: "Novo Cliente",
          link: "/clientes-cadastro",
          parentId: "apps",
        },
        {
          id: "clientes",
          label: "Todos os Clientes",
          link: "/clientes",
          parentId: "apps",
        },
      ],
    },
    {
      id: "tickets",
      label: "Tickets",
      icon: "bx bx-receipt",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsTickets(!isTickets);
        setIscurrentState("Tickets");
        updateIconSidebar(e);
      },
      stateVariables: isTickets,
      subItems: [
        {
          id: "tickets-list",
          label: "Tickets",
          link: "/tickets-list",
          parentId: "tickets",
        },
      ],
    },
  ];

  const menuFinanceiro = [
    {
      id: "financeiro",
      label: "Financeiro",
      icon: "bx bx-money",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsFinanceiro(!isFinanceiro);
        setIscurrentState("Financeiro");
        updateIconSidebar(e);
      },
      stateVariables: isFinanceiro,
      subItems: [
        {
          id: "faturas-aberto",
          label: "Faturas em Aberto",
          link: "/faturas-aberto",
          parentId: "financeiro",
        },
        {
          id: "faturas",
          label: "Faturas",
          link: "/faturas",
          parentId: "financeiro",
        },
      ],
    },
  ];
  if (user && user.data.role === "admin") {
    menuItems = [...menuItems, ...menuAdmin, ...menuRevendedor];
  } else if (user && user.data.role === "revendedor") {
    menuItems = [...menuItems, ...menuRevendedor];
  }else if (user && user.data.setores) {
    user.data.setores.forEach((setor) => {
      if (setor.setor === "Financeiro") {
        menuItems = [...menuItems, ...menuFinanceiro];
        return true;
      }
      return false;
    });
  }

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
