import React, { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";

const TicketDetails = ({ ticket, dateFormat, ticketList, getChildren, setTicketData }) => {
  const [status, setStatus] = useState(null);
  const [lastTicket, setLastTicket] = useState({});
  const [loadingReply, setLoadingReply] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoadingReply(true);

    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/tickets/${ticket?.id}/status`,
        data: {
          status: status,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        getChildren(ticket?.id);
        setTicketData(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoadingReply(false);
  };

  useEffect(() => {
    if (ticket) {
      setLastTicket(ticketList[ticketList.length - 1]);
      setStatus(ticket?.status);
    }
  }, [setLastTicket, setStatus, ticket, ticketList]);

  return (
    <React.Fragment>
      <Col xxl={3}>
        <Card>
          <CardHeader>
            <h5 className="card-title mb-0">Detalhes</h5>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <Table className="table-borderless align-middle mb-0">
                <tbody>
                  <tr>
                    <td className="fw-medium">Ticket</td>
                    <td>
                      #<span id="t-no">{ticket?.id}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Cliente</td>
                    <td id="t-client">{ticket?.requester?.name}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium d-flex align-items-center">
                      Responsável
                    </td>
                    <td id="t-client">{ticket?.assignee?.name}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Status</td>
                    <td>
                      <Input
                        type="select"
                        className="form-select"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="novo">Novo</option>
                        <option value="em_progresso">Em Progresso</option>
                        <option value="fechado">Fechado</option>
                      </Input>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Prioridade</td>
                    <td>
                      {ticket?.prioridade === "baixa" ? (
                        <div
                          className="badge rounded-pill bg-info fs-12"
                          id="ticket-status"
                        >
                          Baixa
                        </div>
                      ) : ticket?.prioridade === "media" ? (
                        <div
                          className="badge rounded-pill bg-warning fs-12"
                          id="ticket-status"
                        >
                          Média
                        </div>
                      ) : (
                        <div
                          className="badge rounded-pill bg-danger fs-12"
                          id="ticket-status"
                        >
                          Alta
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Início</td>
                    <td id="c-date">{dateFormat(ticket?.created_at)}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Última atualização</td>
                    <td id="d-date">{dateFormat(lastTicket?.created_at)}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Ação</td>
                    <td>
                      <Button
                        onClick={handleUpdate}
                        color="primary"
                        className="btn btn-danger"
                        disabled={loadingReply}
                      >
                        Atualizar
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
        {/* <Card>
          <CardHeader>
            <h6 className="card-title fw-semibold mb-0">Files Attachment</h6>
          </CardHeader>
          <CardBody>
            <div className="d-flex align-items-center border border-dashed p-2 rounded">
              <div className="flex-shrink-0 avatar-sm">
                <div className="avatar-title bg-light rounded">
                  <i className="ri-file-zip-line fs-20 text-primary"></i>
                </div>
              </div>
              <div className="flex-grow-1 ms-3">
                <h6 className="mb-1">
                  <Link to="#" className="text-body">
                    Velzon-admin.zip
                  </Link>
                </h6>
                <small className="text-muted">3.2 MB</small>
              </div>
              <div className="hstack gap-3 fs-16">
                <Link to="#" className="text-muted">
                  <i className="ri-download-2-line"></i>
                </Link>
                <Link to="#" className="text-muted">
                  <i className="ri-delete-bin-line"></i>
                </Link>
              </div>
            </div>
            <div className="d-flex  align-items-center border border-dashed p-2 rounded mt-2">
              <div className="flex-shrink-0 avatar-sm">
                <div className="avatar-title bg-light rounded">
                  <i className="ri-file-ppt-2-line fs-20 text-danger"></i>
                </div>
              </div>
              <div className="flex-grow-1 ms-3">
                <h6 className="mb-1">
                  <Link to="#" className="text-body">
                    Velzon-admin.ppt
                  </Link>
                </h6>
                <small className="text-muted">4.5 MB</small>
              </div>
              <div className="hstack gap-3 fs-16">
                <Link to="#" className="text-muted">
                  <i className="ri-download-2-line"></i>
                </Link>
                <Link to="#" className="text-muted">
                  <i className="ri-delete-bin-line"></i>
                </Link>
              </div>
            </div>
          </CardBody>
        </Card> */}
      </Col>
    </React.Fragment>
  );
};

export default TicketDetails;
