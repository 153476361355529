import React, { useState } from "react";
import { Alert, Card, CardBody, Col, Row, Button, Badge } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import axios from "axios";

const UpgradeAccountNotice = ({ contrato, app_id }) => {
  const [loading, setLoading] = useState(false);
  const desbloqueioConfinanca = async (id_contrato) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/mobile/desbloqueio/${id_contrato}`,
        {
          app_token: app_id,
        }
      );

      if (response.data) {
        if (response.data.codigo && response.data.codigo === 200) {
          toast.success("Desbloqueio executado com sucesso.");
        } else {
          toast.error("Não foi possível executar o desbloqueio.");
        }
      } else {
        toast.error(
          response.message ? response.message : "Ocorreu um erro desconhecido."
        );
        console.log(response);
      }
    } catch (error) {
      toast.error("Ocorreu um erro desconhecido.");
      console.log(error);
    }
    setLoading(false);
  };
  const STATUS_MAP = {
    P: { description: "Pré-contrato", color: "secondary" },
    A: { description: "Ativo", color: "success" },
    I: { description: "Inativo", color: "warning" },
    N: { description: "Negativado", color: "danger" },
    D: { description: "Desistiu", color: "info" },
  };

  const INTERNET_STATUS_MAP = {
    A: { description: "Ativo", color: "success" },
    D: { description: "Desativado", color: "secondary" },
    CM: { description: "Bloqueio Manual", color: "warning" },
    CA: { description: "Bloqueio Automático", color: "warning" },
    FA: { description: "Financeiro em atraso", color: "danger" },
    AA: { description: "Aguardando Assinatura", color: "info" },
  };

  const VELOCIDADE_STATUS_MAP = {
    N: { description: "Normal", color: "primary" },
    R: { description: "Reduzida", color: "warning" },
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody className="p-0">
              {contrato.restricao_auto_desbloqueio === "S" && (
                <Alert
                  className="alert alert-warning border-0 rounded-0 m-0 d-flex align-items-center"
                  role="alert"
                >
                  <FeatherIcon
                    icon="alert-triangle"
                    className="text-warning me-2 icon-sm"
                  />
                  <div className="flex-grow-1">
                    {contrato.motivo_restricao_auto_desbloq.replace(
                      "disponível para o contrato ",
                      "disponível para o contrato #"
                    )}
                  </div>
                </Alert>
              )}

              <Row className="align-items-end">
                <Col sm={8}>
                  <div className="p-3">
                    <p className="fs-16 lh-base">
                      <span className="fw-semibold">
                        Contrato #{String(contrato.id).padStart(4, "0")}
                        {": "}
                      </span>
                      {contrato.contrato}{" "}
                    </p>
                    <div className="mt-3">
                      {contrato.restricao_auto_desbloqueio === "S" ? (
                        <Button color="danger" disabled>
                          Desbloqueio já ativado
                        </Button>
                      ) : contrato.status_internet !== "A" &&
                        contrato.status_internet !== "D" &&
                        contrato.status_internet !== "AA" ? (
                        <Button
                          color="info"
                          onClick={() => desbloqueioConfinanca(contrato.id)}
                        >
                          Desbloqueio de Confiança
                        </Button>
                      ) : (
                        <Button color="dark">Desbloqueio não disponível</Button>
                      )}
                    </div>
                  </div>
                </Col>

                <Col sm={4}>
                  <div className="p-3">
                    <p className="d-flex justify-content-between align-items-center">
                      <strong>Status:</strong>
                      <Badge
                        color={
                          STATUS_MAP[contrato.status]?.color || "secondary"
                        }
                      >
                        {STATUS_MAP[contrato.status]?.description ||
                          "Pré-contrato"}
                      </Badge>
                    </p>
                    <p className="d-flex justify-content-between align-items-center">
                      <strong>Internet:</strong>
                      <Badge
                        color={
                          INTERNET_STATUS_MAP[contrato.status_internet]
                            ?.color || "secondary"
                        }
                      >
                        {INTERNET_STATUS_MAP[contrato.status_internet]
                          ?.description || "Desativado"}
                      </Badge>
                    </p>
                    <p className="d-flex justify-content-between align-items-center">
                      <strong>Velocidade:</strong>
                      <Badge
                        color={
                          VELOCIDADE_STATUS_MAP[contrato.status_velocidade]
                            ?.color || "primary"
                        }
                      >
                        {VELOCIDADE_STATUS_MAP[contrato.status_velocidade]
                          ?.description || "Normal"}
                      </Badge>
                    </p>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UpgradeAccountNotice;
